<template>
  <div class="main">
    <div class="sevice w1000">
      <div class="topTitle flex align-center">
        <div class="f28">·协议&政策·</div>
      </div>
      <div class="flex align-center">
        <div class="box flex align-center justify-center">
          <div class="left flex align-center justify-center">
            <img src="../../assets/images/common/yonghuxieyi.png" alt="" />
          </div>
          <div class="right">
            <div class="f24">店教授服务协议</div>
            <p class="f18" style="color: #999; margin-bottom: 20px">
              更新日期：2022-10-28日
            </p>
            <p class="f16" style="color: #2695fa" @click="previewPDF('https://cogo.pluss.cn/resources/upload/店教授商家商城服务协议.pdf')">
              查看内容
            </p>
          </div>
        </div>
        <div class="box flex align-center justify-center">
          <div class="left flex align-center justify-center">
            <img src="../../assets/images/common/yonghuxieyi.png" alt="" />
          </div>
          <div class="right">
            <div class="f24">儿童政策</div>
            <p class="f18" style="color: #999; margin-bottom: 20px">
              更新日期：2022-10-28日
            </p>
            <p class="f16" style="color: #2695fa" @click="previewPDF('https://cogo.pluss.cn/resources/upload/儿童政策2.pdf')">查看内容</p>
          </div>
        </div>
      </div>
      <div class="flex align-center">
        <div class="box flex align-center justify-center">
          <div class="left flex align-center justify-center">
            <img src="../../assets/images/common/yonghuxieyi.png" alt="" />
          </div>
          <div class="right">
            <div class="f24">店教授隐私声明</div>
            <p class="f18" style="color: #999; margin-bottom: 20px">
              更新日期：2022-10-28日
            </p>
            <p class="f16" style="color: #2695fa" @click="previewPDF('https://cogo.pluss.cn/resources/upload/店教授隐私声明.pdf')">查看内容</p>
          </div>
        </div>
        <div class="box flex align-center justify-center"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    previewPDF(url) {
      // let url = 'https://cogo.pluss.cn/resources/upload/店教授商家商城服务协议.pdf';
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  background-color: #f2f3f4;
  padding: 100px 0;
}
.sevice {
  /* border: 1px solid #e6e6e6; */
  border-collapse: collapse;
  box-sizing: border-box;
}
.topTitle {
  height: 100px;
  background-color: #fafafa;
  box-sizing: border-box;
  padding: 0 100px;
  border: 1px solid #e6e6e6;
}
.sevice .box {
  width: 50%;
  height: 210px;
  padding: 60px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}
.sevice .box .left {
  width: 80px;
  height: 80px;
  background-color: #2887df;
  margin-right: 20px;
}
.sevice .box .left img {
  width: 60px;
}
</style>